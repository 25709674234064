<template>
  <div>
    <word-select-box v-bind="$attrs" @wordSelected="addSelectedWord" @wordAdded="addWord" :disble_add_word="wordIds.length >= 15" />
    <b-row class="match-height mt-3">
      <b-col
        class="col-lg-3 col-md-6 col-12"
        v-for="(word,index) in words"
        :key="`${word}_${index}`"
      >
        <word-card
          v-bind="$attrs"
          :word="word"
          :index="index"
          @confirmDelete="deleteWord"
          ref="word_card"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import WordCard from "./WordCard.vue";
import WordSelectBox from './WordSelectBox.vue';
import service from '../../service';
export default {
  components: {
    WordCard,
    WordSelectBox,
  },
  props: {
    wordIds: {type: Array, required: true},
  },
  data() {
    return {
      is_select_word: false,
      word_options: [],
      selected_word: null,
      word_key: null,
      words: [],
    };
  },
  created() {
    this.getWords()
  },
  methods: {
    async getWords() {
      let filter = { _id: { $in: this.wordIds } };
      if (this.statusFilter){
        filter.status = this.statusFilter;
      }
      let response = await service.getList({
        query:  filter
      });
      this.words = response.data?.data?.list || []
    },
    openCreateModal(){
      this.$refs.modalWord.openCreateModal()
    },
    deleteWord(i){
      const deletedWordId = this.words[i]._id;
      const deletedWordIndex = this.wordIds.findIndex((wordId) => { return JSON.stringify(wordId) === JSON.stringify(deletedWordId)});
      this.wordIds.splice(deletedWordIndex,1)
      this.words.splice(i,1)
    },
    addWord(word) {
      this.wordIds.push(word._id);
      this.words.push(word);
    },
    addSelectedWord(word){
      let existed_word = this.words.find(item => item._id === word._id);
      if (existed_word) {
        return this.$store.dispatch("pushErrorNotify", {
          text: "add a existed word!",
        })
      }
      this.addWord(word);
    },
  },
};
</script>
